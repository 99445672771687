.container {   
  width: 100%;
   display: grid;
  grid-gap: 10px;
  grid-template-columns:1fr 20fr;
  grid-template-areas:
    'header header'
    'sidebar content';
}
.header {
  background-color: #f6f8f9a7;
  border: solid 1px #ffffff;
  border-radius: 5px;
  grid-area: header;
}
.navBar {
  background-color: #f6f8f9a7;
  border: solid 1px #ffffff;
  border-radius: 5px;
  width: 232px;
  grid-area: sidebar;
  
}
.content {
  background-color: #f6f8f9a7;
  border: solid 1px #ffffff;
  border-radius: 5px;
  grid-area: content;
  overflow: auto; 
}

@media screen and (min-width: 20px) and (max-width:  767px){
  .container {width: 100%;display: block;} 
  .header {background-color: #f6f8f9a7; 
    border: solid 1px #ffffff;
    border-radius: 5px;
    margin-bottom: 5px;
   }
  .navBar { background-color: #f6f8f9a7;
    border: solid 1px #ffffff;
    border-radius: 5px;
    width: 100%;
    text-align: center;
   }
  .content { background-color: #f6f8f9a7;
    border: solid 1px #ffffff;
    border-radius: 5px;
    
    overflow: auto; }
}


@media print {
  .header {
    display: none ;
  }
  .navBar {
    display: none ;
  }
  
  }

.flag{

  font-size: 30px;
  margin-top: 45vh;
  text-align: center;
  color:#ffffff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.817);
}
.flag img{
 
   box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.817);
   border-radius: 5px;
}


@media screen and (min-width: 320px) and (max-width: 767px){ 
 .content {
    min-height:750px;
}
  /* .navBar {height: 2150px;} */
}

@media screen and (min-width: 768px) and (max-width: 1023px){ 
  .content {min-height:1750px;}
  .navBar {width: 150px;}
 }