body {
  background-image: url(./../public/img/main/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* Змінює розмір фону, щоб він вміщувався у весь екран */
  background-position-x: center; /* Відцентровує зображення по горизонталі */
  background-position-y: 30%; /* Встановлює вертикальне позиціонування */
  background-attachment: fixed; /* Зафіксувати фонове зображення */
  font-family: Arial, Helvetica, sans-serif;
}
button{
  margin-top:  19px;
  margin-left:15px;
  margin-right: 15px;
  background-color: #506D8B;
    width: 150px;
    border: solid 1px #ffffff;
    border-radius: 5px;
    text-align: center;
    padding: 7px;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
    /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
      letter-spacing: 1px; 
     color:#ffffff ;
      font-size: 13px;
      text-decoration: none;
      cursor: pointer;
  box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.5);
}
button:hover{
  background-color: #5c7590;
}
button:active{background-color: #506D8B;  text-shadow: 2px 2px 5px rgb(185, 4, 4);}



p {
  color: #1d2f43; 
 text-shadow:1px 1px 2px rgb(255, 255, 255);
  font-size: 23px; 
  }

  form p{color: #1d2f43; 
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.387);
    font-size: 17px;
    font-style: italic;   
    letter-spacing: 1px;
  }
  table {
    border-collapse: collapse;
  }
 

