h2 {
    color: #1d2f43; 
text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.91);
font-size: 35px;
margin-bottom: 5px;
}
.main {
    background-color: #f6f8f9a7;
    border: solid 1px #ffffff;
    border-radius: 5px;
    text-align: center;   
    height: 97.8vh;
    display: flex; /* Встановлюємо flex-контейнер */
    flex-direction: column; /* Встановлюємо напрямок відображення на колонки (по вертикалі) */
    justify-content: center; /* Вирівнюємо вміст по вертикалі по центру */
    align-items: center; /* Вирівнюємо вміст по горизонталі по центру */
   
}
.main div{
    margin: 20px;
}
.main form{
    text-align: center;
   
}


  .errorMessage{
    cursor: pointer;
    color:#ffffff;
    font-size: 17px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
      /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
    background-color: #fe2929c4;
    border: solid 1px #610101;
    border-radius: 5px;
    text-align: center;   
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .fogotPass { 
    text-align: right;
    cursor: pointer;
    color: #001eff;

  }