
.container{
    grid-template-columns: 300px 1fr;
    display: grid;
    grid-gap: 10px; 
    }
    
      
.item1{
    padding-top: 10px;
    margin-left: 10px;
    width: 300px;
    /* height: 300px; */
    grid-column:1 / 2; 
    grid-row: 1 / 2; 
    }
.item2{  
    grid-column:2 / 3; 
    grid-row: 1 / 2;
    display: grid; 
    grid-template-columns: 50% 1fr;
    gap: 10px;    
    text-align: center;
    }
    .item1 img{width: 300px;}
.competitionButtonsL{ grid-column:1 / 2; grid-row: 1 / 2; }
.competitionButtonsR{ grid-column:2 / 3;  grid-row: 1 / 2;}
   

.title{
    text-align: center;
    color: #1d2f43; 
    text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.91);
    font-size: 30px;
    font-weight: bold;
      } 
      .title p{
        margin-top:7px;
        text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.91);
        font-size: 23px;
        margin-bottom: 5px;
          } 
          .title p span{
             font-size: 20px;
          }
.modal {
    position: fixed;
    top: 50%; /* Розміщення по вертикалі по центру */
    left: 50%; /* Розміщення по горизонталі по центру */
    transform: translate(-50%, -50%); /* Центрування */
    z-index: 1000; /* Змініть це значення за потреби */
    background: rgb(234, 241, 247);/* Колір фону модального вікна */
    padding: 20px; /* Відступи від контенту */
    border: 1px solid #ddd; /* Рамка */
    border-radius: 5px; /* Закруглені кути */
    max-height: 90vh; /* Максимальна висота для прокручування */
    width: 90%;
    overflow-y: auto; /* Прокручування, якщо контент перевищує висоту */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тінь */
      }
.confirmButtonsModal {
  text-align: center;
    position: fixed;
    top: 50%; /* Розміщення по вертикалі по центру */
    left: 50%; /* Розміщення по горизонталі по центру */
    transform: translate(-50%, -50%); /* Центрування */
    z-index: 2000; /* Змініть це значення за потреби */
    background: rgb(234, 241, 247);/* Колір фону модального вікна */
    padding: 20px;  /*Відступи від контенту */
    border: 1px solid #ddd; /* Рамка */
    border-radius: 5px; /* Закруглені кути */
    max-height: 90vh; /* Максимальна висота для прокручування */
    max-width: 50vh;
    /* width: 90%; */
    overflow-y: auto; /* Прокручування, якщо контент перевищує висоту */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тінь */
      }
.confirmButtonsModal div{text-align: center;}
      
.modalGrids {
    position: fixed;
    top: 50%; /* Розміщення по вертикалі по центру */
    left: 50%; /* Розміщення по горизонталі по центру */
    transform: translate(-50%, -50%); /* Центрування */
    z-index: 1000; /* Змініть це значення за потреби */
    background: rgb(234, 241, 247);/* Колір фону модального вікна */
    padding: 20px; /* Відступи від контенту */
    border: 1px solid #ddd; /* Рамка */
    border-radius: 5px; /* Закруглені кути */
    max-height: 90vh; /* Максимальна висота для прокручування */
    width: 96%;
    overflow-y: auto; /* Прокручування, якщо контент перевищує висоту */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тінь */
   padding-top: 100px;
      }
 
      
.modal p {color: #1d2f43;
   text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.91);
    font-size: 20px; 
} 
/* Модальне вікно з заявкою спортсменів */

.modalExitButton {/* кнопки Модального вікна з сітками */
  position: fixed;
  top: 2%;
  left: 12%;
  transform: translateX(-50%); /* Змінено для центрування по горизонталі */
  z-index: 1100; /* Змінено значення z-index для забезпечення правильного відображення над модальним вікном */
  width: 15%;
  padding-bottom: 30px;  
  border-radius: 5px;}

.modalButtons {/* кнопки Модального вікна з сітками */
  position: fixed;
  top: 0;
  left: 49%;
  transform: translateX(-50%); /* Змінено для центрування по горизонталі */
  z-index: 1100; /* Змінено значення z-index для забезпечення правильного відображення над модальним вікном */
  width: 95.5%;
  background-color: #f6f8f9a7;
  padding-bottom: 30px;  
  border-radius: 5px;
}
.modalButtonsNone{ display: none;}

.modalDiv{ padding-top: 50px;}
.scaileForm{width: 50px;}
.gridContainer {
    padding:10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Адаптивні колонки */
    grid-gap: 20px; /* Відступи між блоками */
  }  
  .scaileButt{ background-color: #0c891d;}
  .scaileButt:hover{ background-color: #0c891d;}
  .scaileButtAbort { background-color: #bb1a1a;}
  .scaileButtAbort:hover { background-color: #bb1a1a;}
  .gridContainerForScaile {
    padding:10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Адаптивні колонки */
    grid-gap: 20px;  /*Відступи між блоками */
  }  

  .gridContainerForClubChoise {
    /* padding:10px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Адаптивні колонки */
    grid-gap: 10px;  /*Відступи між блоками */
  }  
 
  .athleteBlock { box-shadow: inset  0 0 7px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;
    border: solid 1px #ffffff;
    border-radius: 5px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 8px;
    background-color: #f0f0f0; /* Колір блоку атлета */
    /* Відступи всередині блоку */
   
  }
  .athleteBlock1{
    box-shadow:inset  0 0 7px rgb(255, 255, 255);
    color:#f0f0f0;
    letter-spacing: 0.8px;
    border: solid 1px #ffffff;
    border-radius: 5px;
    padding-top: 10px;
    background-color: #506D8B; /* Колір блоку атлета */
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  .athleteBlock img{
    width: 135px;
    height: 155px;
    border-radius: 5px;
    box-shadow:   0 0 7px rgba(0, 0, 0, 0.5);
  } 
  .name{padding: 5px;
    font-size: 15px;
    font-weight: bold;    
 }

  .clubBlock { box-shadow: inset  0 0 7px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;
    border: solid 1px #ffffff;
    border-radius: 5px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 8px;
    background-color: #f0f0f0; /* Колір блоку атлета */
   display: grid;
  }
  .handleCortChange { box-shadow: inset  0 0 7px rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;
    border: solid 1px #ffffff;
    border-radius: 5px;
    padding: 10px;
    background-color: #f0f0f0; /* Колір блоку атлета */
  }
  .handleGridsMakeButton {
     display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .handleGridsMakeButton button {
    margin-top: auto;
    margin-bottom: 10px;
    margin-left: 39px;
  }
  
  .cortNumber{
text-align: center;
font-size: 20px;
font-weight: bold;
  }
  .clubBlock1{
    padding-right: 8px;
    box-shadow:inset  0 0 7px rgb(255, 255, 255);
    color:#f0f0f0;
    letter-spacing: 0.8px;
    border: solid 1px #ffffff;
    border-radius: 5px;
    padding-top: 10px;
    background-color: #506D8B; /* Колір блоку атлета */
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
       display: grid;
       
      
  }
  .clubLogo{
    grid-column: 1/2;
    grid-row: 1/2;
    padding-left: 10px;
  } 
  .clubData{   
  grid-column: 2/3;
    grid-row: 1/2;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
   .clubCreator{font-size: 14px;}
  .clubRegion{font-size: 12px;}
  .clubCoachTitle{font-size: 10px;}
  .clubCoach{font-size: 12px;}
 .athleteId{font-size: 12px;text-align: center; font-style: italic;}
  .clubLogo img{
    width: 110px;
    height: 110px;
    border-radius: 5px;
    box-shadow:   0 0 7px rgba(0, 0, 0, 0.5);    
  } 
.clubFooter{
  font-size: 15px;
  text-align: center;
   grid-column: 1/3;
    grid-row: 2/3;
}
 
 
  .buttonR{
       margin-left: 65%;        
  }
  .buttonL{margin-right: 65%;}

  /* Повідомленна про запит до бд при заявці спортсменів */
  .errorMessage {
    cursor: pointer;
    color:#ffffff;
    font-size: 20px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
      /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
    background-color: #cd0303ca;
    border: solid 1px #610101;
    border-radius: 5px;
    text-align: center;   
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }  
  .errorMessage ul{ color:#ffffff;}
    .errorMessage1{
      cursor: pointer;
      color:#ffffff;
      font-size: 15px;
      letter-spacing: 2px;
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
        /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
      background-color: #bb0505ca;
      border: solid 1px #610101;
      border-radius: 5px;
      text-align: justify;
      text-indent: 40px;  
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      margin-right: 0px;
      margin-left: 10px;
    }
    .errorMessage1 div{
      color:#ffffff;
      margin-top: 20px;
      font-size: 22px;
      letter-spacing: 2px;
      text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
    }
    .successMessage{  
      cursor: pointer;
      color:#ffffff;
    font-size: 20px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
      /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
    background-color: #2684b3ca;
    border: solid 1px #0551e9a3;
    border-radius: 5px;
    text-align: center;   
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    margin-left:10px ;
    }
    .confirmMessage{  
      cursor: pointer;
      color:#ffffff;
    font-size: 20px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
      /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
    background-color: #17820ba3;
    border: solid 1px #265c13ca;
    border-radius: 5px;
    text-align: center;   
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    margin-left:10px ;
    }
.teamContaner{
  display: grid;
  grid-gap: 10px; 
}
.teamChange{
  padding-left: 10px;
  padding-bottom: 10px;
  grid-column: 1/2;
  grid-row: 1/2;  
}
.entryPrintChangeMenu{
  text-align: right;
  padding-right: 10px;
   grid-row: 1/2;
  grid-column: 2/3; 
}
.poomsaeCountMenu{
  padding-top: 75px;
  grid-column: 2/3; 
  grid-row: 1/2; 
}
.poomsaeSwitcherMenu{
   padding-top: 78px;
  grid-column: 3/4; 
  grid-row: 1/2; 
}
.poomsaeSwitcherMenu span,.poomsaeSwitcherMenuForSecretary span{
  font-size: 13px; 
  text-shadow:1px 1px 2px rgb(255, 255, 255);
  color: #1d2f43; 
}
.poomsaeSwitcherMenuForSecretary{
   padding-top: 5px;
  grid-column: 3/4; 
  grid-row: 1/2; 
}


.poomsaeCategoryMenu{ 
   text-align: right;
  padding-top: 93px;
  grid-column: 4/5; 
  grid-row: 1/2;  
}
.poomsaeCategoryMenu1{ 
   text-align: right;
  padding-top: 35px;
  grid-column: 4/5; 
  grid-row: 1/2;  
}
.poomsaeTeamChangeMenu{
  text-align: right;
  padding-right: 10px;
  padding-top: 0px;
  grid-column: 5/6; 
  grid-row: 1/2;  
}
.poomsaeTeamChangeMenu2{
  text-align: right;
  padding-right: 10px;
  padding-top: 35px;
  grid-column: 5/6; 
  grid-row: 1/2;  
}
.teamChange2{
  padding-left: 10px;
  padding-bottom: 20px;
  grid-row: 2/3;
  grid-column: 1/2;
}
.teamChangeMenu{
  text-align: right;
  padding-right: 10px;
   grid-row: 2/3;
  grid-column: 2/3; 
}

.teamChangeMenuSpan{
  padding-right: 15px;
  text-shadow:1px 1px 2px rgb(255, 255, 255);
}
.button {
  width: 170px;
  margin-top: 9px;
  font-size: 12px;
}
.redButton {background-color:#bb1a1a;
  width: 170px;
  margin-top: 9px;
  font-size: 12px;}
  .redButton2 {background-color:#bb1a1a;}
/* ////////////////////////////////////////////////////// */
/* Друк заявки */
.printContent{width:100%;  background-color:#ffffff;}
.printHeader {width:100%;  padding-top:10px;  text-align:center;  font-size:12px;}
.printTitle{  font-size: 12px;  font-weight: bold;} 
.printTable{
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10px;
  width: 98%;  
  margin-bottom: 20px;
}

.printTable tbody td{
  border:black solid 1px ;
   font-size: 10px;
}

.printTable tbody{  border:black solid 2px ;}
.printTable thead{text-align:center;font-weight:bold; }
.printTable tfoot td{
  border:0;
  padding-top: 10px;
  padding-left: 0;
  font-size: 10px;
}


.printTableHeader{  border:black solid 2px ;}
.printTableHeader td{
  text-align: center;
  font-weight: bold;
  border:black solid 1px ;
  font-size: 10px;
}
.td2{text-align: left;}

.foodEntryThead td{font-size: 14px;}
.foodEntryTBody td{font-size: 13px;}

.headerTd1{
  width: 3%;
  text-align: center;
  }
.td1{
  text-align: center;
}
.td3{ text-align: center;}
.td4{ text-align: center;width:75px;}
.td5{ text-align: center;width:70px;}
.td6{ text-align: center;width:170px;}
.td7{white-space: nowrap;}
.td8{ text-align: center;}
.td9{ text-align: center;}
.td10{width:100px;}

.footerBlock{width: 100%;}


.infoDescription {
 font-size: 10px;
  text-decoration: overline;
  position: relative;
  top: 10px;
}



.h1{
  color: #1d2f43; 
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.91);
  font-size:30px;
  text-align: center;
  margin-bottom: 5px;}
  .h2{
text-decoration:underline;
    color: #1d2f43; 
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.91);
  font-size: 25px;
  text-align: center;
  margin-bottom: 25px;
  }
  .h3{  
        color: #1d2f43; 
      text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.91);
      font-size: 23px;
      text-align: center;
      margin-bottom: 25px;
      font-weight: bold;
      }

.adminButton{
  /* font-weight: bold; */
  font-family: 'Times New Roman', Times, serif;
  width: 220px;
  font-size: 18px;
  }

.butt:active{
   text-shadow: 2px 2px 5px rgb(185, 4, 4);
 /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
   letter-spacing: 1px; 
  color:#ffffff ;
   font-size: 20px;
   text-decoration: none;   
}
.butt:hover{
  background-color: #5c7590;
}
.butt{
  font-family: 'Times New Roman', Times, serif;
   width: 305px;
  height: 55px;
   text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
 /* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
   letter-spacing: 1px; 
  color:#ffffff ;
   font-size: 20px;
   text-decoration: none;  
   margin-bottom: 10px; margin-right: 10px;
   padding: 0; 
} 

.exitButton{font-family: 'Times New Roman', Times, serif;
  background-color:#bb1a1a;
  padding: 0;
  width: 290px;
  height: 55px;
  margin-bottom: 40px;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
/* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
  letter-spacing: 1px; 
 color:#ffffff ;
  font-size: 20px;
  text-decoration: none;
 }
 
 .exitButton2{font-family: 'Times New Roman', Times, serif;
  background-color:#bb1a1a;
  padding: 0;
  width: 200px;
  height: 35px;
  margin-bottom: 40px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
/* Горизонтальний відступ, вертикальний відступ, розмір тіні, колір тіні */
  letter-spacing: 1px; 
 color:#ffffff ;
  font-size: 20px;
  text-decoration: none;
 }
.containerForAthletResult{  text-align: center;  display: grid;  grid-template-columns: 90% 10%;  }
.itemForAthletResult{font-size: 10px;font-weight: bold; padding-bottom: 3px;}
.containerAdmin{
  text-align: center;
  display: grid;
  /* grid-gap: 5px;  */
  }
.itemPoomse1{grid-column:1/2;grid-row:1/2;text-align:left;width:33%} 
.itemPoomse2{grid-column:2/3;grid-row:1/2;text-align:left;width:33%}
.itemPoomse3{grid-column:3/4;grid-row:1/2;text-align:left;width:33%} 
  
.itemAdmin1{  grid-column:1 / 2;   grid-row: 1 / 2; } 
.itemAdmin2{   grid-column:2 / 3;   grid-row: 1 / 2;}
.itemAdmin3{  grid-column:3 / 4;   grid-row: 1 / 2; } 
.itemAdmin4{  grid-column:4 / 5;  grid-row: 1 / 2; } 
 .itemAdmin5{ grid-column:5 / 6; grid-row: 1 / 2; } 

/* grids.js */ 
 
.contentTitleItem1{text-align: center; display: grid;}
.contentTitleItem2{width: 100%;display: grid;}
.boxForDateInput{text-align: center;padding: 20px;}
.boxForDate{display: grid;}
.title1{  grid-column:1 / 2;   grid-row: 1 / 2;}
.title2{  grid-column:2 / 3;   grid-row: 1 / 2;}
.title3{  grid-column:3 / 4;   grid-row: 1 / 2;}
.title6{  grid-column:4 / 5;   grid-row: 1 / 2;}
.title7{  grid-column:5 / 6;   grid-row: 1 / 2;}
.title3ForEnrtyPrint{grid-column:3/4;grid-row:1/2;text-align: right;}


.title4{padding-left:35%; grid-column:1 / 2;  grid-row: 1 / 2;}
.title5{padding-left:2% ; grid-column:2 / 3;   grid-row: 1 / 2;}
.title1 label{color: #1d2f43; 
  text-shadow:1px 1px 2px rgb(255, 255, 255);}

/* Звіт про жеребкування в сітках */
.divReport h4{ font-size: 18px; text-indent: 30px;}
.divReport p{font-size: 18px;}
.divReport a{
color:  #285487;
text-decoration: none;
font-style: italic;
font-weight: bold;
}
.divReport a:visited {color:   rgb(185, 4, 4);;}

.listForCoach{display: none;}

.statistic p{
font-size: 16px;
text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.91);
} 

.gridContainerForCategories {
  padding:10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Адаптивні колонки */
  grid-gap: 10px; /* Відступи між блоками */
} 
.gridContainerForCategories a{
  color:#1d2f43 ;
  text-decoration: none;
}



.archiveBlock {  
  letter-spacing: 1px;
   border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px; }

  .archiveBlockTitle {
    padding-top: 10px;
    font-weight: bold;
    text-align: center;  
  }
  .archiveBlock a{
    color: inherit;
    text-decoration: none;
    }
  

.categoryBlock {  
  letter-spacing: 1px;
  border: solid 1px #ffffff;
  border-radius: 5px;
  padding: 8px;
  padding-bottom: 8px; 
}
.tableForAthleteResult{
  background-color: #ffffff; /* Колір блоку атлета */
  width: 98%;
  border-radius: 5px;
  border: #ffffff solid 1px;
  padding: 10px;
}
.zebra{background-color: #ffffff;}
.zebra1{background-color: #f0f0f0;}
.tableForAthleteResult table{border: #000000 solid 2px;border-collapse: collapse!important;} 
.tableForAthleteResult table thead{border: #000000 solid 2px;font-weight: bold;}
.tableForAthleteResult table tbody td{font-size: 12px!important;}
.tableForAthleteResult table td{
  border: #000000 solid 1px;
  text-align: center;
padding: 5px;

}
  .boxTitile{
   box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5);
  background-color: #f0f0f0; /* Колір блоку атлета */
    width: 94%;
   margin-left: 3%;
    border-radius: 5px;
    height: 100%;
  }
  .boxTitile1{
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5);
   background-color: #f0f0f0; /* Колір блоку атлета */
   margin-left: auto;
   margin-right: auto;
     width: 98%;
     height: 100%;
     margin-bottom: 15px;
     border-radius: 5px;
   }

 .categoryBlockTitle {
  font-size: 14px;
  padding-top: 10px;
  font-weight: bold;
  text-align: center;  
}

.cellPoomsae{box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5);
  background-color: #f0f0f0; /* Колір блоку атлета */  
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.headerPoomsaeCell{
 padding-bottom: 10px;
    padding-top: 5px;
    text-align: center;
    font-size: 14px;
    font-style: italic;   
}
.coupelCellContaner{
padding:5px;
display: grid;
grid-template-columns: repeat(auto-fill, minmax(90px, 1fr)); /* Адаптивні колонки */
grid-gap: 10px; /* Відступи між блоками */
}
.coupelCellImg{ width: 90px;}
.coupelCellImg img{
  box-shadow:  0 0 5px rgba(0, 0, 0, 0.5);
  width: 90px;
  /* height: 153px; */
border-radius: 5px;}

.coupelCellName{ 
  font-size: 12px;
  text-align: center;
}

.cell{
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.5);
  background-color: #f0f0f0; /* Колір блоку атлета */
  display: grid;
  grid-template-areas: 
  "header header header header header"
  "itemImg itemData itemData itemData itemData"
  "itemImg itemData itemData itemData itemData"
  "footer footer footer footer footer";

 padding: 15px;
 margin-top: 5px;
 margin-bottom: 15px;
 border-radius: 5px;
}
.categoryData{
  line-height: 13px;
  padding-left: 10px;
  padding-top: 3px;
 font-size: 13px;
 grid-area: itemData;
}
.categoryData div{
  padding-bottom: 5px;
}

.region{
  padding-top: 5px;
  text-align: center;
  font-size: 14px;
  font-style: italic;
  }
.categoryImg{
grid-area: itemImg;
} 
.categoryImg img{
  box-shadow:  0 0 5px rgba(0, 0, 0, 0.5);
  width: 120px;
  height: 153px;
border-radius: 5px;}
.buttonOfPersonalResult{
  width: 120px;
 
}
.categoryName{
  
  text-align: center;
  padding-bottom: 15px;
   grid-area: header;
}
.categoryRating{
  padding-top: 5px;
  font-size: 13px;
  text-align: center;
  grid-area: footer;
 
}
.categoryRating button{margin-top: 3px;padding:3px;}
.categoryRating div{
  padding-bottom: 5px;
} 
.coachName{
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}


/* CustomCheckbox.css */

.switchBox { margin-top: 13px;}
.switchBox input[type="checkbox"] {
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  outline: none;
  background: #adacac;
  border-radius: 20px;
  transition: 0.5s;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}
.switchBox input:checked[type="checkbox"] {
  background: #6284a9;
}
.switchBox input[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: #ffffff;
  transition: 0.5s;
  transform: scale(1.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.switchBox input:checked:before {
  left: 20px;
}
.ageCategorySwitcher{
  font-size: 25px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: #1d2f43; 
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.817);
   font-style: italic;
  font-weight: bold;
}
.ageCategorySwitcher1{
  font-size: 20px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: #1d2f43; 
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.817);
   font-style: italic;
  font-weight: bold;
}
.ageCategoryDescription{
   color: #1d2f43; 
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.817);
   font-style: italic;
}
.switchers{ margin-left:0px;  display: grid; grid-template-columns:16.6%
}
.switchersSmall{ margin-left:0px;  display: grid;}
.switch1L{ grid-column:1 / 2;  grid-row: 1 / 2;padding-left: 10px;}
.switch1R{ grid-column:1 / 2;  grid-row: 2 / 3;padding-left: 10px;}
.switch2L{ grid-column:2 / 3;  grid-row: 1 / 2;}
.switch2R{ grid-column:2 / 3;  grid-row: 2 / 3;}
.switch3L{ grid-column:3 / 4;  grid-row: 1 / 2;}
.switch3R{ grid-column:3 / 4;  grid-row: 2 / 3;}
.switch4L{ grid-column:4 / 5;  grid-row: 1 / 2;}
.switch4R{ grid-column:4 / 5;  grid-row: 2 / 3;}
.switch5L{ grid-column:5 / 6;  grid-row: 1 / 2;}
.switch5R{ grid-column:5 / 6;  grid-row: 2 / 3;}
.switch6L{ grid-column:6 / 7;  grid-row: 1 / 2;}
.switch6R{ grid-column:6 / 7;  grid-row: 2 / 3;}

.switch1LSmall{grid-column:1/2;grid-row:1/2;padding-left:10px;margin-left: 10px;}


.swichButtContaner{ display: grid; width: 98%; }
.switchButL{grid-column:1 / 2;  grid-row: 1 / 2; margin-left: 40px;}

.switchButR{grid-column:2 / 3;  grid-row: 1 / 2; text-align: right; margin-right: 40px;}
.printButton{ margin-bottom: 40px; margin-left: 40px;}

.calcOl li{
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;

}
.info{
  font-size: 15px;
  text-indent: 20px; 
  padding-left: 30px; 
  padding-right: 30px;
  padding-bottom: 5px;
}
.paddingLeft{padding-left: 30px;}

 
.mailTableResultByAthlets { 
  border:2px solid #000000;
  margin: 10px;
  width: 98%;
  padding: 10px;      
}

.mailTableResultByAthlets td:nth-child(1){ width: 25px;}
.mailTableResultByAthlets td:nth-child(2){ padding-left: 10px; }
.mailTableResultByAthlets td:nth-child(3){ width: 200px;}
.mailTableResultByAthlets td:nth-child(4){ width: 100px;}
.mailTableResultByAthlets td:nth-child(5){ width: 250px;}
.mailTableResultByAthlets td:nth-child(6){ width: 70px;}
.mailTableResultByAthlets td:nth-child(7){ width: 170px;}
.mailTableResultByAthlets td:nth-child(8){ width: 200px;}
.mailTableResultByAthlets td:not(:nth-child(2)){ 
  text-align: center;
}
.mailTableResultByAthlets tfoot{border:none}

.mailTableResultByAthlets td:not(tfoot td){ 
    border:1px solid #000000;
    font-size: 13px; 
}

.headerTable{
   font-weight: bold;
   border-bottom:2px solid #000000; 
}
.headerTable td{ padding: 5px; 
  border:1px solid #000000;

}


.commentTitle{text-align: center;}

 .commentWriter{ text-align: center;}
 .commentInput{
  padding: 5px;
  width: 79%;
  margin-top: 20px;
  vertical-align: bottom;
  box-shadow: inset  0 0 7px rgba(0, 0, 0, 0.5);
  border:1px #ffffff solid;
  border-radius: 5px;
}
.commentInput:focus{outline: none; border:#285487 1px solid;  }

.commentButton{vertical-align: bottom}
.commentBox{margin-top: 20px; height: 400px;overflow-y: auto;}
.commentWriper{background-color: #f0f0f0;
width: 94%;
margin-left: 2%;margin-top: 20px;padding: 10px;
box-shadow: inset  0 0 7px rgba(0, 0, 0, 0.5);
border-radius: 5px;
}
.commentLogo{
  width: 30px;
  border-radius: 60px;
  box-shadow:  0px 0px 7px rgba(0, 0, 0, 0.5);

}
.commentName, .commentDate, .commentTime{
  margin-bottom: 13px;
  font-size: 15px;
  margin-left: 10px;
  font-weight: bold;
}
.commentTextDiv{
  padding-top: 20px;padding-left: 20px;padding-bottom: 20px;
  text-indent: 20px;
  white-space: pre-wrap;}

.commentLine{
  width: 93%;
  height: 1px;
  margin-left: 40px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

.basketForComment{
  width: 15px; 
  margin-left: 20px;
cursor: pointer;
}
.editHand{
  width: 25px; 
  margin-left: 40px;
 cursor: pointer;
}


  @media screen and (min-width: 768px) and (max-width: 1023px){
    .commentInput{ width: 70%;}
    .commentLine{width: 90%;}
    }

  @media screen and (min-width: 320px) and (max-width: 767px){
    .modalGrids { max-height: 2000px;  width: 86%;}
     .modal { max-height: 90vh;min-width:95vw;}
     .item1 img{width: 350px;}
     .item1{width: 100%; margin-left: 0;}
     .teamContaner{display: block;text-align: center;}
     .teamChangeMenu{text-align: center; padding-right: 0;}
     .teamChangeMenuSpan{padding: 0;}
     .title3ForEnrtyPrint{text-align: center;margin-bottom: 30px;}
     .modalContent{margin-top: 900px;margin-right: 50px;}
    .containerAdmin{overflow: auto;}
    .switch1L{padding-left: 0;} 
    .switch1R{padding-left: 0;}
    .switchButL{margin-left:0;}
    .switchButR{margin-right:0;text-align: center;}
    .switchButR .exitButton2{width: 220px;}
    .commentWriper{width: 90%;}
    .commentLine{width: 85%;}
    
   }
   .noPrint{margin-bottom: 10px;}
   /* Інші стилі, які можуть бути застосовані для друку */
   @media print {  
    .teamContaner{display: none;}
    .noPrint{display: none;}
    .container {display: none; }
    .printTable {margin-top: 0;}
    .printButton button{ display: none; }
    .modalButtons {display: none;}
    tr{
      page-break-inside: avoid; /* Заборона розривати рядок таблиці на декілька сторінок */
    }
  
   thead {display: table-header-group }/* друк заголовків таблиці на кожній сторінці*/
   tfoot {display: table-footer-group}
   .modalGrids {
    position: fixed;
    top: 0%; 
    left:0%; 
    transform: translate(0%, 0%); /* Центрування */
    z-index: 1000; /* Змініть це значення за потреби */
    background: rgb(234, 241, 247);/* Колір фону модального вікна */
    padding:0; /* Відступи від контенту */
    border:none;
    border-radius: 5px; /* Закруглені кути */
    max-height: 90vh; /* Максимальна висота для прокручування */
    width: 100%;
    overflow-y: auto; /* Прокручування, якщо контент перевищує висоту */
    box-shadow: none; /* Тінь */
   padding-top: 0px;
      }
  }